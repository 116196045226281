<template>
  <Title title="Prenez rendez-vous" :showBack="true" :showIcon="true" />
  <Calendar :legends="[{color: 'var(--color--dark-grey)', text:'Indisponibilités'}, {color: 'var(--color--pink)', text:'RDV programmés'}]" :isBoostPack="true" />
</template>

<script>
import Calendar from "../../components/Calendar/Calendar";
import Title from "../../components/General/Title";

export default {
  name: "BoostPackMeeting.vue",
  components: {Calendar, Title}
}
</script>
